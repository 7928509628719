import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, ColorTokens, theme } from '@shapeable/theme';
import { 
  PageContextDefinition, 
  FocusElementProvider, useEmbedComponents, 
  EmbedComponentsProvider,
  LayoutShell,
  SiteFooterLayout,
  UiConfig,
  UiProvider,
} from '@shapeable/ui';

import { VERY_LIGHT_BROWN, DARK_GREEN, BLUE, MEDIUM_BROWN } from '../theme';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { PostsProvider } from '../gatsby/providers/posts-provider';
import { MenusProvider } from '../gatsby/providers/menus-provider';
import { LayoutStyles, themeOverrides } from '../theme';

export type LayoutPropTypes = Classable & HasChildren & {
  path?: string;
  data?: any;
  pageContext?: PageContextDefinition;
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles --------> 

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles}
  `,
});

const FooterStyles = breakpoints({
  base: css`
    flex-grow: 0;
    flex-shrink: 0;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});

// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
  Footer: styled(SiteFooterLayout)`${FooterStyles}`,

};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const embedComponents = useEmbedComponents();

  const ref = React.useRef();
  const focusRef = React.useRef();


  const config: UiConfig = {
    layouts: {pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    theme: themeOverrides,
    langStrings: LANG_STRINGS,
    header: {
      showThemeMode: true,
      barHeight: 3,
    },
    componentProps: {
      SiteFooterLayout: {
        colors: {
          background: VERY_LIGHT_BROWN
        }
      },
      PageHeader: {
        themeMode: 'dark',
      },
      SiteMenuBar: {
        colors: {
          background: 'dark',
        }
      },
      MenuItem: {
        colors: {
          bold: BLUE,
          hover: {
            text: 'strong',
            bold: 'strong',
            background: BLUE,
          },
          active: {
            text: MEDIUM_BROWN,
          },
        }
      },
    },
    entity: {
      dataProviders: [
        MenusProvider, PostsProvider
      ]
    }
  }

  return (
    <UiProvider value={config}>
    <FocusElementProvider value={focusRef}>
    <EmbedComponentsProvider value={{
      ...embedComponents,
    }}>
      <My.Container
        id="#container"
        ref={ref}
        hideOverflow={false}
        className={className}
        includeTooltip
        modalColor={DARK_GREEN}
        tooltipProps={{
          backgroundColor: VERY_LIGHT_BROWN,
          globalEventOff: 'click',
        }}
      >
        <My.Body>
          {children}
        </My.Body>
        <My.Footer />
      </My.Container>
    </EmbedComponentsProvider>
    </FocusElementProvider>
    </UiProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

