import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { BANNER_KNOWLEDGE_HUB, BLUE_OVERLAY } from '../../theme';
import { SliceLayoutKnowledgeHub } from '../slice-layouts';
import { EntityTitleHeader } from '@shapeable/ui';

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const PageLayoutKnowledgeHub: PageLayoutComponent = 
(props) => {
  const { className, entity, slices } = props;
  
  return (
    <My.Container className={className}>
      <EntityTitleHeader overlayColor={BLUE_OVERLAY} backgroundImage={BANNER_KNOWLEDGE_HUB} entity={entity} />
      {slices}
      <SliceLayoutKnowledgeHub entity={entity} />
    </My.Container>
  )
};