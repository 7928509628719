import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { MenusProvider as Provider, platformProviderValue } from '@shapeable/ui'

/** Adds extra language fields to query */
const MENUS_QUERY = graphql`
  query PlatformMenusQuery {
    platform {
      allMenus {
        id slug name
        items {
          id 
          name
          name_fr
          slug path
          page { 
            disabled 
            path
            openGraph { image { thumbnails { full { url } } } }
          }
          children { 
            id 
            name 
            name_fr
            slug 
            path
            page {
              disabled 
              path
              openGraph { image { thumbnails { full { url } } } }
            }
          }
        }
      }
    }
  }`;

export const MenusProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(MENUS_QUERY), 'allMenus' ) }>{children}</Provider>;
};