import { 
  PageKnowledgeHubLayout, PageKnowledgeHubLayoutProps, SliceColorStrip, 
  SliceColorStripProps, SliceContactForm, SliceContactFormProps, SliceContentEntityQuote, 
  SliceHeaderDivider, SliceHeaderDividerProps, SliceInlineBanner, SliceIntro, createSliceLayout
 } from '@shapeable/ui';
import { BRAND_COLORS } from '../../theme';

export const SliceLayoutIntroBackground = createSliceLayout(
  SliceInlineBanner, {
    boundary: 'content',
    aspectRatio: {
      base: 21 / 9,
    }
  }
);

export const SliceLayoutKnowledgeHub = createSliceLayout<PageKnowledgeHubLayoutProps>(
  PageKnowledgeHubLayout, {
    boundary: 'content-bleed',
    verticalPadding: 'medium',
    props: {
      showAllTab: true,
      entityTypes: { 
        Post: { filterFieldNames: [ "type", "topics", "challenges", "authors"] }, 
        Video: { filterFieldNames: [ "topics", "challenges", "people" ] } 
      }
    }
  }
);

export const SliceLayoutKeyMessage = createSliceLayout(
  SliceContentEntityQuote, {
    boundary: 'none',
  }
)

export const SliceLayoutContactForm = createSliceLayout<SliceContactFormProps>(
  SliceContactForm, {
    props: {
      variant: 'reveal',
    }
  }
);

export const SliceLayoutHeader = createSliceLayout<SliceHeaderDividerProps>(
  SliceHeaderDivider, {
    boundary: 'none',
    props: {
    }
  }
);

export const SliceLayoutColorStrip = createSliceLayout<SliceColorStripProps>(
  SliceColorStrip, {
    boundary: 'none',
    props: {
      colors: BRAND_COLORS
    }
  }
);

export const SliceLayoutIntro = createSliceLayout(
  SliceIntro, {
    verticalPadding: 'small',
  }
);
